.testimonials {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* margin-right: 20px; */
  flex-basis: calc(33.33% - 20px);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.testimony {
  display: flex;
  align-items: center;
  background-color: #f7f6f6;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.testimony__image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
}

/* .testimony__content {
    color: grey;
} */

.testimony__text {
  margin-bottom: 10px;
  margin-top: 10px;
  color: grey;
}

.testimony__author {
  font-weight: 700;
  font-style: normal;
  color: green;
}
.testimony_designation {
  font-size: smaller;
  margin-top: 10px;
  color: grey;
  font-size: small;
}
@media screen and (max-width: 768px) {
  .testimonials {
    flex-direction: column;
  }

  .testimony__image {
    margin-bottom: 10px;
  }
}
